import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [iframeUrl, setIframeUrl] = useState('https://www.gomaster.pro/');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');

    if (page === 'deactivation') {
      setIframeUrl('https://www.gomaster.pro/deactivate_account?lang=en_us&RTL=no');
    } else if (page === 'privacy') {
      setIframeUrl('https://www.gomaster.pro/privacy_policy?lang=en_us&RTL=no');
    }
  }, []);

  return (
    <div className="App" style={{ margin: 0, padding: 0, height: '100vh', width: '100vw' }}>
      <iframe 
        src={iframeUrl} 
        style={{ border: 0, height: '100%', width: '100%' }} 
        title="Embedded Content"
      />
    </div>
  );
}

export default App;
